'use strict'

$(document).ready(function () {

    if ($('#waiting-list-modal').length) {
        var submit = $('#waiting-list-submit')

        submit.one('click', function () {
            var $this = $(this)
            var $emailInput = $('#waiting-list-input-email')
            var $close = $('#waiting-list-close')

            $this.addClass('is-loading')

            $.post("https://hook.eu2.make.com/8wklf9a713lv27nxu9fohzmy3xv2y26f", { email: $emailInput.val() })
                .always(function() {
                    $this
                        .removeClass('is-loading primary-btn')
                        .text('Laiškas išsiųstas!')
                        .addClass('no-click is-success')

                    $emailInput.val('')
                    $close.text('Uždaryti')
                });
        })
    }
})
