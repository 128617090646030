/* ==========================================================================
Bulma Inputs
========================================================================== */

'use strict'

//Bulma tags
function initBulmaTags() {
  if ($('.bulma-tags').length) {
    bulmaTagsinput.attach()
  }
}

//Bulma steps
function initBulmaSteps() {
  if ($('.steps-wrapper').length) {
    bulmaSteps.attach()
  }
}

//Bulma Iconpicker
function initBulmaIconpicker() {
  if ($('.iconpicker-wrapper').length) {
    bulmaIconpicker.attach()
  }
}
